import { Dispatch } from 'react';
import { format } from 'date-fns';
import axiosInstance from '../../api/axiosInstance';
import {
  request,
  authSessionExistSuccess,
  authSessionExistFailure,
  authorizationFailure,
  authorizationSuccess,
  updateProfile,
  updateProfileFailure,
  updateProfileSuccess,
  makeFormRequestSuccess,
  changeProfileRequest,
  changeProfileRequestSuccess,
  changeProfileRequestFailure,
  continueSessionSuccess,
  setTokenExp,
  resetContinueSession,
} from './';
import { removeItemSessionStorage, resetSessionStorage, setToSessionStorage } from '../../utils/sessionStorageHelpers';
import { toBinary } from '../../utils/stringHelpers';
import { User } from './model';
import { parseJwt } from '../../utils/parseJwt';
import { removeItemLocalStorage, saveLocalStorageData } from '../../utils/localStorageHelpers';
import { resetAlerts } from '../notifyAlerts';

interface IPicture {
  name: string;
  type: string;
  size: number;
  base64: string;
}

export type updateProfileType = {
  lastName: string;
  firstName: string;
  middleName?: string;
  dateOfBirth: string;
  specialization: string;
  title: string;
  organization: string | number | null;
  department: string | number | null;
  subdepartment: string | number | null;
  phoneNumber: string;
  picture?: IPicture;
  roleField?:string[];
};
export type signUpType = { email: string; password: string; rePassword: string; lastName: string; firstName: string };
type restorePasswordType = {
  password: string;
  uidb64: string;
  token: string;
}

export const checkSessionBeforeSignIn = (data: { email: string; password: string; }) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());
  
  try {
    const response = await axiosInstance().post('/account/session/', data);
    dispatch(authSessionExistSuccess(!!response.data?.session))
  } catch (error: any) {
    dispatch(authSessionExistFailure(error?.response?.data));
  }
}

export const signIn = (data: { email: string; password: string; }) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  try {
    const response = await axiosInstance().post('/account/login/', data);
    dispatch(resetAlerts());
    dispatch(authorizationSuccess(response.data));
    resetSessionStorage();
    if(response.status === 200){
      let key = window.navigator.userAgent.replace(/[^0-9]/g, '');

      const jsonStrData = JSON.stringify(response.data);
      const converted = toBinary(jsonStrData);
      const encData = window.btoa(converted);
      setToSessionStorage([key,encData]);
      const token = parseJwt(response.data.tokens.access)
      dispatch(setTokenExp(token?.exp));
      await saveLocalStorageData('continue_session_ignored',false);
    }
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data));
  }
};

export const continueSession  = (access:any, refresh: string, user: User ) => async (dispatch: Dispatch<unknown>) => {
  try {
    const response = await axiosInstance(access).post('account/refresh/', {refresh});
    if(response.status === 200){
      const access = response.data.access;
      const userProfile = {...user};
      userProfile.tokens = {access,refresh}

      let key = window.navigator.userAgent.replace(/[^0-9]/g, '');
      const jsonStrData = JSON.stringify(userProfile);
      const converted = toBinary(jsonStrData);
      const encData = window.btoa(converted);
      for await (let key of Object.keys(sessionStorage)){
        if(isNaN(parseInt(key))) removeItemSessionStorage(key);
      }
      const token = parseJwt(access)
      dispatch(setTokenExp(token?.exp));
      await saveLocalStorageData('dataLastUpdate',Date.now());

      setToSessionStorage([key,encData]);
      dispatch(continueSessionSuccess(userProfile))

    }
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data));
  }
};

export const signOut = ({ refresh, access }: { refresh: string; access: string; }) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  try {
    await axiosInstance(access).post('/account/logout/', { refresh: refresh });
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data ?? {}));
  } finally {
      dispatch(authorizationSuccess(null));
      dispatch(setTokenExp(''));
      dispatch(resetContinueSession());
      await resetSessionStorage();
      await removeItemLocalStorage('dataLastUpdate','continue_session_ignored');
      for await (let key of Object.keys(sessionStorage)){
        if(isNaN(parseInt(key))) removeItemSessionStorage(key);
      }
  }
};

export const signUp = ({ email, password, rePassword, lastName, firstName }: signUpType) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  const postData = {
    email,
    password,
    re_password: rePassword,
    last_name: lastName,
    first_name: firstName,
  };

  try {
    const response = await axiosInstance().post('/account/registration/', postData);

    dispatch(authorizationSuccess(response.data));
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data ?? {}));
  }
};

export const verifyEmail = (token: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  try {
    const response = await axiosInstance().get(`/account/email-verification/?token=${token}`);
    dispatch(authorizationSuccess(response.data));

  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data ?? {}));
  }
};

export const restorePasswordRequest = (email: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  try {
    const response = await axiosInstance().post('/account/password-reset-request/', { email });

    dispatch(authorizationSuccess(response.data));
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data ?? {}));
  }
};

export const restorePassword = (data: restorePasswordType) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  try {
    const response = await axiosInstance().post('/account/password-reset-complete/', data);

    dispatch(authorizationSuccess(response.data));
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data ?? {}));
  }
};

export const makeFormRequest = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());

  try {
    await axiosInstance(access).post('/account/registry/blueprint/');
    dispatch(makeFormRequestSuccess());
  } catch (error: any) {
    dispatch(authorizationFailure(error?.response?.data));
  }
};

export const putProfile = (ulid: string, access: string, data: updateProfileType) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());
  await dispatch(updateProfile());

  const updatedData = {
    ...data,
    dateOfBirth: data?.dateOfBirth ? format(new Date(data.dateOfBirth), 'yyyy-MM-dd') ?? null : null,
    ulid,
  };

  try {
    const response = await axiosInstance(access).put(`/account/profile/search/${ulid}/`, updatedData);
    const data = response.data;
    data.ulid = ulid;
    dispatch(updateProfileSuccess(data));
  } catch (error: any) {
    dispatch(updateProfileFailure(error?.response?.data));
  }
};

export const putChangeProfile = (ulid: string, access: string, data: updateProfileType) => async (dispatch: Dispatch<unknown>) => {
  dispatch(request());
  await dispatch(changeProfileRequest());

  const updatedData = {
    ...data,
    dateOfBirth: data?.dateOfBirth ? format(new Date(data.dateOfBirth), 'yyyy-MM-dd') ?? null : null,
    ulid,
  };

  try {
    const response = await axiosInstance(access).put(`/account/profile/search/${ulid}/`, updatedData);
    const data = response.data;
    data.ulid = ulid;
    dispatch(changeProfileRequestSuccess());
  } catch (error: any) {
    dispatch(changeProfileRequestFailure(error?.response?.data));
  }
};
