import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import {Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  TBlockCreateNewPatient,
  TBody,
  TFormContainer,
  TFormWrapper,
  THint,
  TIDInputWr,
  TInfoSecondary,
  TInputsWr, 
  TMessage,
  TTitlePatientData,
} from './styled';
import Radio from '../../../../components/shared/Radio';
import { TBottomButtonsContainer, TReferralListWr } from '../../styled';
// import { ReactComponent as WarnIcon } from '../../../../icons/warn.svg';
// import { ReactComponent as WarnRedIcon } from '../../../../icons/warn-red.svg';
import Button, { SIZE, VARIANT } from '../../../../components/shared/Button';
import { IStep } from '..';
import InfoModal from '../../../../components/shared/InfoModal';
import ReferralCard from './ReferralCard';
import { Patient as PatientModel } from '../../../../store/patient/model';
import { getCountries } from '../../../../store/countries/selectors';
import { fetchReferrals, setReferralPatient } from '../../../../store/referral/thunkActions';
import InputSearch from '../../../../components/shared/InputSearch';
import { fetchPatients, putPatient } from '../../../../store/patient/thunkActions';
import { getPatientLoading, getPatients } from '../../../../store/patient/selectors';
import { getFromSessionStorage } from '../../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../../constants/sessionStorageKeys';
import { checkPermitViewREFERRAL, getErrors, getTokens } from '../../../../store/auth/selectors';
import { getReferrals } from '../../../../store/referral/selectors';
import PatientBlock from './PatientBlock';
import SelectPatientBlock from './SelectPatientBlock';
import { REFERRAL_TYPE } from '../../../../constants/referral';
import { resetReferrals } from '../../../../store/referral';
import Loader from '../../../../components/shared/Loader';
import usePrepareDataToValidateAmbulatoryCards from "./usePrepareDataToValidateAmbulatoryCards";
import { validateAmbulatoryCard } from "../../../../store/referral/thunkActions/apiActions";
import {resetPatients} from "../../../../store/patient";
import { Referral } from '../../../../store/referral/model';
import { useNavigate } from 'react-router-dom';


const { PATIENT } = SESSION_STORAGE_KEYS;

const DEFAULT_NATIONALITY = 'by';
const FOREIGN_NATIONALITY = 'foreign';

const checkSummNumberValid = (value: string) => {
  let checkNumber = ((7 * +value[0]) + (3 * +value[1]) + +value[2] + (7 * +value[3]) + (3 * +value[4]) + +value[5] + (7 * +value[6]) +
    ((3 * +value.charCodeAt(7)) - 55) + +value[8] + (7 * +value[9]) + (3 * +value[10]) + (+value.charCodeAt(11) - 55) + (7 * (+value.charCodeAt(12) - 55) )) % 10;
  return +value[13] === +checkNumber;
};

const checkPassportPatternBY = (value: string) => {
  const regex = new RegExp(/^([0-9]{7}[A-Z]{1}[0-9]{3}[A-Z]{2}[0-9]{1})$/);
  return regex.test(value);
};
const checkPassportPattern = (value: string) => {
  const regex = new RegExp(/^[A-Z0-9]+$/);
  return regex.test(value);
};

const Patient: FC<IStep> = ({ goNext, onCancelCreation, goBack }) => {
  const dispatch = useDispatch();
  const countries = useSelector(getCountries);
  const patients = useSelector(getPatients);
  const patientLoading = useSelector(getPatientLoading);
  const tokens = useSelector(getTokens);
  const patientReferrals = useSelector(getReferrals);
  const errors = useSelector(getErrors);

  const formMethods = useForm({ mode: 'onChange' });
  const { control, setValue,getValues, formState, trigger, clearErrors, resetField, setError } = formMethods;
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: 'ambulatoryCards'
  });

  const idPassportFieldValue = useWatch({ control, name: 'idPassport' });

  const [selectedNationality, setSelectedNationality] = useState(DEFAULT_NATIONALITY);
  const [showModal, setShowModal] = useState(false);
  const [patientRequested, setPatientRequested] = useState(false);
  const [idPassportSubmittedValue, setIdPassportSubmittedValue] = useState<string>('');
  const [enabledEdit, setEnabledEdit] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientModel | null>(null);
  const [focused, setFocused] = useState(false);
  const [idPassportKey, setIdPassportKey] = useState<number>(Date.now());
  const [isPatientDataConfirm, setPatientDataConfirm] = useState(false);
  const [  createNewProfilePatient, setCreateNewProfilePatient] = useState(false);

  const { prepareDataToValidateAmbulatoryCards } = usePrepareDataToValidateAmbulatoryCards();

  const hasPassportError = !!formState?.errors?.idPassport;

  const [countryOptions, rbCountryId] = useMemo(() => {
    const filteredCountries = countries.filter(({ alpha }) => alpha !== 'by')
      .map(({ id, name }) => ({ value: id, label: name })) ?? [];
    const belarusCountryId = countries.find(({ alpha }) => alpha === 'by')?.id;

    return [filteredCountries, belarusCountryId]
  }, [countries]);

  const setValuesToForm = useCallback((data) => {
    const { idPassport, country, lastName, firstName, middleName, dateOfBirth, gender, ulid, ambulatoryCards } = data;
    const formattedDate = dateOfBirth ? new Date(dateOfBirth) : null;
    if(idPassport) {
      setValue('idPassport', idPassport);
      setIdPassportSubmittedValue(idPassport);
    };
    setValue('country', country ? +country : rbCountryId);
    setValue('lastName', lastName);
    setValue('firstName', firstName);
    setValue('middleName', middleName);
    setValue('dateOfBirth', formattedDate);
    setValue('gender', gender);
    setValue('ulid', ulid ?? '');
    resetField('ambulatoryCards');
    if (ambulatoryCards?.length) {
      let cards:any[] = [...ambulatoryCards];
      let hasPriority = cards.find((item:any) => item?.priority)
      if(!hasPriority) cards = cards?.map((item:any,index:number) => {
        let card = {...item ?? {}}
        if(!!card?.['priority']) return card;
        card['priority'] = !!index ? false : true;
        return card;
    }) ?? []
      append(cards, { shouldFocus: false });
    } else {
      append({ number: '', organization: '', customOrganization: '', priority: true, isNewCard: true }, { shouldFocus: false });
    }
    setSelectedNationality(country && rbCountryId !== +country ? FOREIGN_NATIONALITY : DEFAULT_NATIONALITY);
    setTimeout(() => clearErrors(), 1);
    setSelectedPatient(data);
    setIdPassportKey(Date.now());
    ulid && tokens?.access && dispatch(fetchReferrals(tokens.access, { ulid }));

  }, [resetField, append, rbCountryId, setSelectedPatient, clearErrors, tokens?.access, 
    dispatch, setValue,setIdPassportKey,setIdPassportSubmittedValue]);

  const handleGetPatient = useCallback(async (idPassport?: string) => {
    //first clear state
    dispatch(resetPatients())
    if (!!formState?.errors?.idPassport) return;
    setPatientRequested(false);

    const id = idPassport || idPassportFieldValue;

    if (selectedNationality === DEFAULT_NATIONALITY) {
      const checkPassport = checkPassportPatternBY(id);
      const checkSum = checkSummNumberValid(id)
      if (!checkPassport) {
        setError('idPassport', {types: {required: "Номер паспорта должен быть 1111111А111АА1"}})
      }
      if (checkPassport && !checkSum) {
        setError('idPassport', {types: {required: 'Проверьте введенные данные'}})
      }
    }

    if (selectedNationality !== DEFAULT_NATIONALITY) {
      const checkPassport = checkPassportPattern(id)

      if (!checkPassport || id.length < 7) {
        setError('idPassport', {types: {required: "Паспорт должен быть минимум 7 символов"}})
      }
    }

    resetField('firstName');
    resetField('lastName');
    resetField('middleName');
    resetField('country');
    resetField('dateOfBirth');
    resetField('gender');
    resetField('ambulatoryCards');
    resetField('ulid');
    append({ number: '', organization: '', customOrganization: '', priority: true, isNewCard: true }, { shouldFocus: false });
    setSelectedPatient(null);

    !formState.errors.idPassport && tokens?.access && await dispatch(fetchPatients(tokens.access, id, selectedNationality === DEFAULT_NATIONALITY));
    setPatientRequested(true);
    setIdPassportSubmittedValue(idPassportFieldValue)
  }, [append, resetField, tokens?.access, dispatch, idPassportFieldValue, setIdPassportSubmittedValue, formState, selectedNationality, setError]);

  useEffect(() => {
    dispatch(resetReferrals());
    const [
      id, ulid, idPassport, country, gender, dateOfBirth, ambulatoryCards, firstName, lastName, middleName
    ] = getFromSessionStorage(
      PATIENT.id, PATIENT.ulid, PATIENT.idPassport, PATIENT.country, PATIENT.gender, PATIENT.dateOfBirth,
      PATIENT.ambulatoryCards, PATIENT.firstName, PATIENT.lastName, PATIENT.middleName,
    );
    if (idPassport) {
      const patient = {
        idPassport, country, lastName, firstName, middleName, gender, ambulatoryCards: JSON.parse(ambulatoryCards),
        dateOfBirth, id: +id, ulid: ulid ?? '',
      };
      setValuesToForm(patient);
      setPatientRequested(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (patientRequested && patients && !selectedPatient) {
      if (patients.length === 1) {
        setValuesToForm(patients[0]);
      } else if (!patients?.length) {
        setSelectedPatient({} as PatientModel);
        setIdPassportSubmittedValue(idPassportFieldValue);
        setEnabledEdit(true);
      }
    }
  }, [setValuesToForm, patients, patientRequested, selectedPatient,setIdPassportSubmittedValue,idPassportFieldValue]);

  const disabledFields = useMemo(() => !patientRequested || (patientRequested && !enabledEdit),
    [enabledEdit, patientRequested]);

  const selectPatientFromList = useCallback((patient) => {
    setValuesToForm(patient);
  }, [setValuesToForm]);

  
  const onCancelEditPatient = useCallback(() => {
    handleGetPatient()
    setEnabledEdit(false);
    setPatientDataConfirm(false);
  }, [setEnabledEdit,handleGetPatient,setPatientDataConfirm]);
  
  const onModalToggle = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  
  const formSubmit = useCallback(async (data) => {
    if(!idPassportFieldValue) {
      setError('idPassport', {types:{required: 'Поле не может быть пустым'}})
    }
    const payloadPrepareAmbulatoryCards = prepareDataToValidateAmbulatoryCards(data);
    
    const formattedData = {
      ...data,
      ulid: selectedPatient?.ulid ?? '',
      dateOfBirth: format(data.dateOfBirth, 'yyyy-MM-dd'),
      country: selectedNationality === DEFAULT_NATIONALITY ? rbCountryId : data.country,
    };
    
    tokens?.access && await dispatch(validateAmbulatoryCard(tokens.access, payloadPrepareAmbulatoryCards))
    
    const filteredAmbulatoryCards = formattedData?.ambulatoryCards?.filter((item: any) => {
      return typeof item.id === 'number';
    });
    
    selectedPatient?.ulid && tokens?.access && await dispatch(putPatient(selectedPatient.ulid, tokens.access, { ...formattedData, ambulatoryCards: filteredAmbulatoryCards }));
    setEnabledEdit(false);
    setSelectedPatient({ ...selectedPatient, ...formattedData });
    setPatientDataConfirm(true);
  }, [selectedNationality, setPatientDataConfirm, selectedPatient, setEnabledEdit, dispatch, tokens?.access, 
    rbCountryId, prepareDataToValidateAmbulatoryCards, idPassportFieldValue, setError]);
  
  const onEditPatient = useCallback(() => {
    // dispatch(getValidateAmbulatoryCard())
    setEnabledEdit(true);
    setPatientDataConfirm(false)
  }, [setEnabledEdit, setPatientDataConfirm]);

  const onContinue = useCallback(async () => {
    selectedPatient && dispatch(setReferralPatient(selectedPatient));
    goNext && goNext();
  }, [goNext, selectedPatient, dispatch]);

  const addAmbulatoryCard = useCallback((e) => {
    e.preventDefault();
    let cards:any[] = fields?.map((card:any) => {
      card['priority'] = false
      return card;
    });
    replace([...cards, { id: '', number: '', organization: '', customOrganization: '', priority: true, isNewCard: true }])
  }, [replace,fields]);

  const removeAmbulatoryCard = useCallback((index: number) => {
    let cards:any[] = [...fields]
    if(cards[index]?.['priority']){
      let updatedCard = {...cards[0],priority:true};
      update(0,updatedCard)
    }

    remove(index);
  }, [remove,update,fields]);

  const setPriorityAmbulatoryCard = useCallback((cardIndex: number,allFields:any[]) => {
    let copyFields = [...allFields]?.map((item:any, ItemIndex:number) => {
      if(ItemIndex === cardIndex) item['priority'] = true;
      else item['priority'] = false;
      return item;
    })
    replace(copyFields)
  }, [replace]);


  const searchResultText = useMemo(() => {
    if (hasPassportError) return null;
    if (patients?.length && !createNewProfilePatient) {
      if (patients?.length > 1 && !selectedPatient?.ulid) {
        return 'Найдено несколько пациентов. Выберите подходящего или создайте новый профиль пациента'
      }
      if (patients?.length === 1 || !!selectedPatient) {
        return 'Пациент найден'
      }
      if (patients?.length === 0) {
        return 'Совпадений не найдено. Создайте профиль'
      }
    }else {
      return 'Совпадений не найдено. Создайте профиль'
    }
    }, [patients, hasPassportError, selectedPatient, createNewProfilePatient]);



  const createNewPatientProfile = () => {
    let nationality = selectedNationality
    setSelectedPatient({} as PatientModel);
    setEnabledEdit(true);
    setValuesToForm({});
    setSelectedNationality(nationality)
    setIdPassportSubmittedValue(idPassportFieldValue);
    setCreateNewProfilePatient(true);
  }
  useEffect(() => {
    if(idPassportFieldValue !== selectedPatient?.idPassport) {
      setEnabledEdit(false)
    }
  }, [idPassportFieldValue, selectedPatient?.idPassport, setEnabledEdit ])

  useEffect(() => {
    if( hasPassportError) {
      setPatientRequested(false);
      setSelectedPatient(null);
      dispatch(resetPatients())
      resetField('country');
      resetField('lastName');
      resetField('firstName');
      resetField('middleName');
      resetField('dateOfBirth');
      resetField('gender');
      resetField('ulid');
      resetField('ambulatoryCards');
    }
  }, [resetField, dispatch, hasPassportError]);

  const isPassportSearchActive = useMemo(() => {
    return !!selectedPatient?.idPassport === idPassportFieldValue || ( patientRequested && idPassportSubmittedValue === idPassportFieldValue )
  },[patientRequested,idPassportSubmittedValue,idPassportFieldValue,selectedPatient]);

  const onClearPassportId = useCallback(async () => {
    setValue('idPassport', undefined);
    await trigger('idPassport');
    setPatientRequested(false);
    setSelectedPatient(null);
    setIdPassportSubmittedValue('');
    setIdPassportKey(Date.now())

  },[setIdPassportKey,setIdPassportSubmittedValue,setSelectedPatient,setPatientRequested,trigger,setValue]);

  const isPatientFindedInList = useMemo(() => {
    return !!patients?.find((p:any) => p?.idPassport === idPassportSubmittedValue || p?.idPassport === idPassportFieldValue)
  },[patients,idPassportSubmittedValue,idPassportFieldValue]);

  const navigate = useNavigate();
  const ableToViewREFERRAL = useSelector(checkPermitViewREFERRAL);

  const goToMolecularProfilingDetails = useCallback((id?: string) => {
    if (ableToViewREFERRAL) {
      onContinue();
      setTimeout(() => navigate(`/molecular-profiling/detail/${id}/referral`), 100);
    };
    return;
  }, [ableToViewREFERRAL, navigate, onContinue]);


  return (
    <Loader enabled={patientLoading}>
      <TTitlePatientData>Данные пациента</TTitlePatientData>
      <TFormWrapper>
        <FormProvider {...formMethods}>
          <TFormContainer>
            <TInputsWr>
              <Radio
                selected={selectedNationality}
                value={DEFAULT_NATIONALITY}
                name='nationality'
                label='Гражданин РБ'
                onChange={async (value) => {
                  setValue('idPassport', undefined);
                  await trigger('idPassport');
                  setPatientRequested(false);
                  setSelectedPatient(null);
                  setIdPassportSubmittedValue('');
                  setIdPassportKey(Date.now());
                  return setSelectedNationality(value)
                }}
              />
              <Radio
                selected={selectedNationality}
                value={FOREIGN_NATIONALITY}
                name='nationality'
                label='Иностранный гражданин'
                onChange={async (value) => {
                  setValue('idPassport', undefined);
                  await trigger('idPassport');
                  setPatientRequested(false);
                  setSelectedPatient(null);
                  setIdPassportSubmittedValue('');
                  setIdPassportKey(Date.now())
                  return setSelectedNationality(value)
                }}
              />
            </TInputsWr>
            <TIDInputWr>
              {selectedNationality === DEFAULT_NATIONALITY ?
                <Controller
                  key={idPassportKey}
                  control={control}
                  name={'idPassport'}
                  render={({ field: { onChange, value }, }) => {
                    // let isCorrectValue = acceptFeature ? checkPassportPatternBY(value) && checkSummNumberValid(value) : (value?.length ?? 0) >= 14;
                    let isCorrectValue = checkPassportPatternBY(value);
                    return (
                    <InputSearch
                      cb={(data) => isPassportSearchActive ? onClearPassportId() : handleGetPatient(data)}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      buttonText={'Найти'}
                      placeholder='Укажите идентификационный номер'
                      error={!!formState.errors.idPassport}
                      errorText={formState?.errors?.idPassport?.types?.required}
                      maxLength={25}
                      onChange={({target}) => onChange(target?.value?.toUpperCase() ?? undefined)}
                      value={value}
                      buttonDisabled={!isCorrectValue}
                      isClearButtonActive={isPassportSearchActive}
                      defaultValue={''}
                      onMouseEnter={() => setFocused(true)}
                      onMouseLeave={() => setFocused(false)}
                    />
                    )}}
                />
                :
                <Controller
                  key={idPassportKey}
                  control={control}
                  name={'idPassport'}
                  render={({ field: {onChange, value}, }) => {
                    let isCorrectValue = checkPassportPattern(value) && value?.length >= 7;
                    return (
                    <InputSearch
                      cb={(data) => isPassportSearchActive ? onClearPassportId() : handleGetPatient(data)}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      buttonText={'Найти'}
                      placeholder='Введите номер паспорта или иного ID'
                      error={!!formState.errors.idPassport}
                      errorText={formState?.errors?.idPassport?.types?.required}
                      onChange={({target}) => onChange(target?.value?.toUpperCase() ?? undefined)}
                      value={value}
                      buttonDisabled={!isCorrectValue}
                      isClearButtonActive={isPassportSearchActive}
                      onMouseEnter={() => setFocused(true)}
                      onMouseLeave={() => setFocused(false)}
                    />
                  )}}
                />
              }
              <THint focused={focused}>
                {selectedNationality === 'by' && <><li><b>14</b> символов без пробелов</li><li>Используйте латинский алфавит</li><li>Пример: <b>7637905A001PB6</b></li></>}
                {selectedNationality === 'foreign' && <><li><b>Весь номер</b> без пробелов</li><li>Используйте латинский алфавит</li><li>Пример: <b>4611222222A</b></li></>}
              </THint>
            </TIDInputWr>

            {patientRequested && selectedPatient &&  (
              <PatientBlock
                enabledEdit={enabledEdit}
                isEditDisabled={!selectedPatient?.ulid}
                countryOptions={countryOptions}
                disabledFields={disabledFields}
                selectedNationality={selectedNationality}
                formSubmit={formSubmit}
                onEditPatient={onEditPatient}
                ambulatoryCardFields={fields}
                patientLoading={patientLoading}
                addAmbulatoryCard={addAmbulatoryCard}
                removeAmbulatoryCard={removeAmbulatoryCard}
                setPriorityAmbulatoryCard={setPriorityAmbulatoryCard}
                errors={errors}
                isShowResultText={(patientRequested && !createNewProfilePatient) || !!selectedPatient}
                resultText={searchResultText ?? ''}
                onCancelEditPatient={onCancelEditPatient}
                isPatientDataConfirm={isPatientDataConfirm}
                idPassport={idPassportSubmittedValue ?? ''}
              />
            )}
          </TFormContainer>
        </FormProvider>

        {patientRequested && patients && patients.length > 1 && !selectedPatient && (
          <SelectPatientBlock patients={patients} setSelectedPatient={selectPatientFromList} />
        )}

        {/* {patients && selectedPatient && !!patientReferrals?.length && ( */}
        {selectedPatient && !!patientReferrals?.length && (
          <TReferralListWr disabled={!selectedPatient || !patientReferrals.length}>
            <TInfoSecondary>Другие направления</TInfoSecondary>
            {
              selectedPatient && patientReferrals.length ? (
                <TBody>
                  {patientReferrals
                    ?.filter((data: Referral) => data?.patient?.idPassport === getValues('idPassport')?.toString())
                    ?.map(({ referralId = '', ulid = '', dateCreated, type, status }) => (
                    <ReferralCard
                      key={referralId}
                      id={referralId}
                      ulid={ulid}
                      // @ts-ignore
                      title={REFERRAL_TYPE[type]}
                      date={dateCreated ? format(new Date(dateCreated), 'dd.MM.yyyy') : ''}
                      status={status}
                      goToMolecularProfilingDetails={(id) => goToMolecularProfilingDetails(id)}
                      ableToViewREFERRAL={ableToViewREFERRAL}
                    />
                  ))}
                </TBody>
              ) : <span>Не найдены</span>
            }
          </TReferralListWr>
        )}

        {patientRequested && patients && patients.length > 1 && !selectedPatient && !isPatientFindedInList && (
          <TBlockCreateNewPatient>
            <Button variant={VARIANT.LINK} disabled={enabledEdit} onClick={createNewPatientProfile}>СОЗДАТЬ</Button>
            <TMessage disabled>другой профиль пацента</TMessage>
          </TBlockCreateNewPatient>
        )}

        {/* {patientRequested && selectedPatient && !createNewProfilePatient && <TBlockCreateNewPatient>
          <Button variant={VARIANT.LINK} disabled>СОЗДАТЬ</Button>
          <TMessage disabled>другой профиль пацента</TMessage>
        </TBlockCreateNewPatient> } */}
      

      </TFormWrapper>
      <TBottomButtonsContainer>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={goBack}>Назад</Button>
        <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={onCancelCreation}>Отмена</Button>
        <Button size={SIZE.SMALL} disabled={!formState.isValid || !selectedPatient || !isPatientDataConfirm } onClick={onContinue}>Далее</Button>
      </TBottomButtonsContainer>

      <InfoModal
        title='Пожалуйста, заполните все обязательные поля'
        showModal={showModal}
        onModalToggle={onModalToggle}
        isWarning
        hasCloseButton
        buttons={<Button size={SIZE.SMALL} onClick={onModalToggle}>OK</Button>}
      />
    </Loader>
  );
}

export default Patient;
