import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TAddButton, TAmbulatoryCards, TButtonsContainer, TCustomButton, TInputsWr, TInvertPlusIcon, } from './styled';
import { validations } from '../../../../../utils/validations';
import InputSelect from '../../../../../components/shared/InputSelect';
import Input from '../../../../../components/shared/Input';
import InputDayPicker from '../../../../../components/shared/InputDayPicker';
import { disablePresentAndFutureDay } from '../../../../../utils/dateHelpers';
import Radio from '../../../../../components/shared/Radio';
import AmbulatoryCard from '../AmbulatoryCard';
import { SIZE, VARIANT } from '../../../../../components/shared/Button';
import { Patient } from '../../../../../store/patient/model';
import IconSquareButton from '../../../../../components/shared/IconSquareButton';
import { ErrorsType, Nullable } from "../../../../../types";
import usePrepareAmbulatoryCardsError from "./usePrepareAmbulatoryCardsError";
import { TEditButton, TInfo, TInfoSecondary, TRowWr, TTitlePatientData } from '../styled';
import { format } from 'date-fns';


interface IPatientBlock {
  disabledFields?: boolean;
  enabledEdit: boolean;
  isEditDisabled: boolean;
  patientLoading: boolean;
  selectedNationality: string;
  countryOptions: { value: number; label: string }[];
  formSubmit: (data: Patient) => Promise<void>;
  onEditPatient: () => void;
  addAmbulatoryCard: (e: Event) => void;
  removeAmbulatoryCard: (index: number) => void;
  setPriorityAmbulatoryCard: (index: number, allFields:any[]) => void;
  ambulatoryCardFields: Record<'id', string>[];
  errors?: Nullable<ErrorsType>;
  isShowResultText: boolean;
  resultText: string;
  onCancelEditPatient: () => void;
  isPatientDataConfirm: boolean;
  idPassport: string;
}

const DEFAULT_NATIONALITY = 'by';

function capitalize(s:string | undefined){
    return s && s[0].toUpperCase() + s.slice(1);
}

const PatientBlock: FC<IPatientBlock> = ({ addAmbulatoryCard, removeAmbulatoryCard, idPassport, setPriorityAmbulatoryCard,
  ambulatoryCardFields, formSubmit, onEditPatient, disabledFields, enabledEdit, isEditDisabled, isPatientDataConfirm,
  selectedNationality, countryOptions, errors, isShowResultText, resultText, onCancelEditPatient }) => {

  const { control, register, handleSubmit, formState, trigger, setValue, getValues } = useFormContext();
  const { preparedAmbulatoryCardsError } = usePrepareAmbulatoryCardsError();
  const [isAbleAddNewCard,setAbleAddNewCard] = useState<boolean>(false);

  useEffect(() => {
    let newCards = ambulatoryCardFields?.some((item:any) => !item?.id || isNaN(+item.id));
    setAbleAddNewCard(!newCards);
  },[ambulatoryCardFields,setAbleAddNewCard]);

  const [patientFullName,patientGender,patientBirth] = useMemo(() => {
    if(isEditDisabled) return ['','',''];
    let {lastName,middleName,firstName, gender, dateOfBirth } = getValues();
    let patName = `${capitalize(lastName)} ${capitalize(firstName)} ${capitalize(middleName)}`.trim();
    let patGender = gender === 'Male' ? 'Мужчина' : 'Женщина';
    let patBirth = format(new Date(dateOfBirth), 'dd.MM.yyyy');

    return [patName,patGender,patBirth]
  },[getValues,isEditDisabled]);

  const watchPatientfealds = useWatch({name: ['country', 'dateOfBirth', 'firstName', 'gender', 'lastName', 'middleName']});
  const watchCardfealds = useWatch({name: 'ambulatoryCards'});

  const isConfirmButtonDisabled = useMemo(() => {
    let [ ...restFields ] = watchPatientfealds ?? [];
    let isAllFieldsEmpty = restFields?.every((card:string | number | Date | undefined) => !card);
    let priorityCard = watchCardfealds?.find((card:any) => card?.priority);
    let isCardOrg = !!priorityCard?.organizationId || !!priorityCard?.organization || !!priorityCard?.customOrganization;
    let isCardNumber = !isNaN(parseInt(priorityCard?.number)) && (typeof parseInt(priorityCard?.number) === 'number');

    return isAllFieldsEmpty && !(isCardOrg || isCardNumber);
  },[watchPatientfealds,watchCardfealds]);

  return (
    <>
      {isShowResultText && (
        // <TRowWr direction={resultText?.length ? "space-between" : "flex-end"}>
        <TRowWr direction={"space-between"}>
          <TTitlePatientData>
            {resultText ?? ''}
            {!isEditDisabled && <TInfo>Для продолжения работы подтвердите профиль пациента. При необходимости добавьте амбулаторную карту</TInfo>}
            </TTitlePatientData>
          {isEditDisabled && <TEditButton type='button' disabled={enabledEdit} onClick={() => enabledEdit ? onCancelEditPatient() : onEditPatient()}>Редактировать</TEditButton>}
        </TRowWr>
      )}
      {selectedNationality !== DEFAULT_NATIONALITY ? (
        <Controller
          control={control}
          name='country'
          rules={validations.country}
          render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
            <InputSelect
              inputRef={ref}
              value={value}
              error={invalid}
              onChange={(value) => onChange(value)}
              disabled={disabledFields}
              options={countryOptions}
              placeholder='Страна происхождения *'
              isSearchable
              />
              )}
              />
              ) : (
            <Input
              disabled={true}
              placeholder='Страна происхождения *'
              defaultValue={'Республика Беларусь'}
              type='text'
              marginTop={2}
            />
          )}
      <Input
        disabled={true}
        placeholder='Номер документа'
        defaultValue={idPassport}
        type='text'
        marginTop={2}
      />

      {!isEditDisabled ? (
        <>
          <Input
            disabled={true}
            placeholder='Имя пациента'
            defaultValue={patientFullName}
            type='text'
            marginTop={2}
          />
          <TInputsWr>
            <Input
              disabled={true}
              placeholder='Пол пациента'
              defaultValue={patientGender}
              type='text'
              marginTop={2}
            />
            <Input
              disabled={true}
              placeholder='Дата рождения пациента'
              defaultValue={patientBirth}
              type='text'
              marginTop={2}
            />
          </TInputsWr>
        </>) : (<>
        <TInputsWr>
          <Input
            disabled={disabledFields}
            marginTop={2}
            {...register('lastName', validations.lastName
            )}
            onBlur={async ({target}) => {
              setValue('lastName',capitalize(target.value.trim()));
              await trigger("lastName");
            }}
            error={!!formState.errors.lastName || !!(errors && errors['lastName'])}
            placeholder='Фамилия *'
            type='text'
            />
          <Input
            {...register('firstName', validations.firstName
            )}
            marginTop={2}
            disabled={disabledFields}
            onBlur={async ({target}) => {
              setValue('firstName',capitalize(target.value.trim()));
              await trigger("firstName");
            }}
            error={!!formState.errors.firstName || !!(errors && errors['firstName'])}
            placeholder='Имя *'
            type='text'
            />
        </TInputsWr>
        <TInputsWr>
          <Input {...register('middleName', {...validations.firstName,required:false})} 
            onBlur={({target}) => {
              setValue('middleName',capitalize(target.value.trim()))
            }}
            marginTop={2}
            disabled={disabledFields} 
            placeholder='Отчество' 
            type='text'
          />
          <Controller
            control={control}
            name='dateOfBirth'
            rules={validations.birthDate}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <InputDayPicker
                isBirthDate
                name='dateOfBirth'
                disabled={disabledFields}
                date={value}
                onChange={onChange}
                popperPlacement='right-start'
                placeholderText='Дата рождения *'
                filterDate={disablePresentAndFutureDay}
                error={invalid}
                shouldCloseOnSelect={true}
              />
            )}
          />
        </TInputsWr>
        <TInputsWr>
          <Controller
            control={control}
            name='gender'
            rules={validations.gender}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <>
                <Radio disabled={disabledFields} selected={value} value='Male' label='Мужчина' onChange={onChange} error={invalid} />
                <Radio disabled={disabledFields} selected={value} value='Female' label='Женщина' onChange={onChange} error={invalid} />
              </>
            )}
          />
        </TInputsWr>
      </>)}

      <TAmbulatoryCards>
        <TInfoSecondary dark={true}>Амбулаторная карта</TInfoSecondary>
        <TInfo>Убедитесь, что выбранная амбулаторная карта зарегистрирована в учреждении направителя</TInfo>
        {ambulatoryCardFields.map((item, index) => {
          const error_content = preparedAmbulatoryCardsError(index);
          let itemData:any = {...item}
          return (
            <AmbulatoryCard 
              key={index + item.toString()} 
              cardID={isNaN(+item.id) ? '' : item.id} 
              blockIndex={index} 
              // disabledFields={disabledFields || !!itemData?.isNewCard} 
              disabledFields={!itemData?.isNewCard} 
              canDelete={!!item?.id} 
              // enableEdit={enabledEdit || !!itemData?.isNewCard} 
              enableEdit={!!itemData?.isNewCard} 
              removeAmbulatoryCard={removeAmbulatoryCard} 
              errorContent={error_content}
              setPriorityAmbulatoryCard={setPriorityAmbulatoryCard} />)
        }
        )}
        {!!isAbleAddNewCard && <TAddButton disabled={false}>
          <IconSquareButton disabled={false} invert onClick={addAmbulatoryCard}>
            <TInvertPlusIcon />
          </IconSquareButton>
          Добавить другую амбулаторную карту
        </TAddButton>}
      </TAmbulatoryCards>

      <TButtonsContainer>
        <TCustomButton
          size={SIZE.SMALL}
          variant={VARIANT.BLUE}
          onClick={handleSubmit(formSubmit)}
          disabled={!!(errors && errors['detail']) || isPatientDataConfirm || isConfirmButtonDisabled}
        >
          Подтвердить
        </TCustomButton>
      </TButtonsContainer>
    </>
  );
}

export default PatientBlock;
