import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  TDateAndPaymentFieldsWr,
  TDateInputWr,
  TInputsWr, TLinkButton,
  TRegistrarBlockWr,
  TRegistrarList, TWrongData,
} from './styled';
import { getErrorMessage, validations } from '../../../utils/validations';
import InputSelect from '../../shared/InputSelect';
import InputDayPicker from '../../shared/InputDayPicker';
import Radio from '../../shared/Radio';
import { Organization } from '../../../store/organizations/model';
import { ServiceUser } from '../../../store/serviceUsers/model';
import InputSearch from '../../shared/InputSearch';
import RegistrarBlock from './RegistrarBlock';
import NotFoundBlock from './NotFoundBlock';
import { getFromSessionStorage, removeItemSessionStorage, setToSessionStorage } from '../../../utils/sessionStorageHelpers';
import SESSION_STORAGE_KEYS from '../../../constants/sessionStorageKeys';
import {
  getDepartmentsSelectOptions,
  getOrganizations,
  getOrganizationsSelectOptions,
} from '../../../store/organizations/selectors';
import Button, { SIZE, VARIANT } from '../../shared/Button';
import { Nullable } from '../../../types';
import { disableFutureDays } from '../../../utils/dateHelpers';
import { getErrors } from "../../../store/auth/selectors";
import { resetSelectedServiceUsers } from '../../../store/serviceUsers';
import { TSubTitle, TTitle } from '../../../containers/CreateReferralPage/styled';
import { TInfo } from '../../../containers/CreateReferralPage/steps/Patient/styled';
import { THint } from '../../../containers/CreateReferralPage/steps/DoctorReferral/styled';

const { REGISTRAR } = SESSION_STORAGE_KEYS;

interface IRegistrarForm {
  findServiceUsers: (fullName: string, organizationId?: number) => void;
  serviceUsersList: ServiceUser[];
  selectedSpecialist: Nullable<ServiceUser>;
  setSelectedSpecialist: (specialist: Nullable<ServiceUser>) => void;
  isCustomDoctorValidateHandler: (isValid: boolean) => void;
}

const currentDateObj = new Date();
const currentDate = format(currentDateObj, 'dd.MM.yyyy');

const PAID_PAYMENT_VALUE = 'Paid';
const FREE_PAYMENT_VALUE = 'Free';
const COMMON_SPEED_VALUE = 'Common';
const URGENT_SPEED_VALUE = 'Urgent';
const ORGANIZATION = 'РНПЦ ОМР';
const DEPARTMENT = 'Консультативно-поликлиническое';

function capitalize(s:string | undefined){
  return s && s[0].toUpperCase() + s.slice(1);
}

const RegistrarForm: FC<IRegistrarForm> = ({ selectedSpecialist, setSelectedSpecialist, serviceUsersList, findServiceUsers, isCustomDoctorValidateHandler }) => {
  const { setValue, getValues, clearErrors, control, setError, resetField, formState, trigger } = useFormContext();
  const errors = useSelector(getErrors);
  const dispatch = useDispatch();


  const commonOrganizationFieldValue = useWatch({ control, name: 'commonOrganization' });
  const departmentFieldValue = useWatch({ control, name: 'department' });
  const organizationFieldValue = useWatch({ control, name: 'organization' });
  const fullNameFieldValue = useWatch({ control, name: 'fullName' });

  const [registrarRequested, setRegistrarRequested] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>();
  const [specialistFromSessionStorage, setSpecialistFromSessionStorage] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [registrarSubmittedValue, setRegistrarSubmittedValue] = useState('');

  const organization = useSelector(getOrganizations);
  const organizationsSelectOptions = useSelector(getOrganizationsSelectOptions);
  const departmentsSelectOptions = useSelector(getDepartmentsSelectOptions(selectedOrganization?.id));

  const [isTooltipFocused, setTooltipFocused] = useState(false);

  const selectSpecialist = useCallback((specialistUlid: string) => {
    const specialist = serviceUsersList.find(({ ulid }) => specialistUlid === ulid);
    if(specialist) setSelectedSpecialist(specialist);
  }, [serviceUsersList, setSelectedSpecialist]);

  useEffect(() => {
    if (commonOrganizationFieldValue || organizationFieldValue) {
      const foundedOrganization = organization.find(({ id }) => id === commonOrganizationFieldValue || id === organizationFieldValue);
      setSelectedOrganization(foundedOrganization);
    }
  }, [commonOrganizationFieldValue, organizationFieldValue, organization]);

  useEffect(() => {
    const [specialistUlid] = getFromSessionStorage(REGISTRAR.specialistUlid);
    if (specialistUlid && !selectedSpecialist && !specialistFromSessionStorage && serviceUsersList.length) {
      selectSpecialist(specialistUlid);
      setSpecialistFromSessionStorage(true);
    }
  }, [selectedSpecialist, selectSpecialist, registrarRequested, specialistFromSessionStorage, serviceUsersList]);

  useEffect(() => {
    const [commonOrganization, organization, organizationCustom, fullName, lastName, firstName, creationDate, comment, payment, speed] = getFromSessionStorage(
      REGISTRAR.commonOrganization, REGISTRAR.organization, REGISTRAR.organizationCustom, REGISTRAR.fullName, REGISTRAR.lastName, REGISTRAR.firstName, 
      REGISTRAR.creationDate, REGISTRAR.comment, REGISTRAR.payment, REGISTRAR.speed);
    if ((fullName || lastName) && !isCreateNew) {
      const formattedDate = creationDate ? new Date(creationDate) : null;
      setValue('commonOrganization', +commonOrganization);
      setValue('organization', +organization);
      setValue('organizationCustom', organizationCustom);
      setValue('fullName', fullName || lastName);
      setValue('lastName', lastName);
      setValue('firstName', firstName);
      setValue('creationDate', formattedDate);
      setValue('comment', comment);
      setValue('payment', payment);
      setValue('speed', speed);
      setTimeout(() => clearErrors(), 1);
      setRegistrarRequested(true);

    }
  },
    [isCreateNew, clearErrors, setValue,]);

  const showDepartmentField = useMemo(() =>
    selectedOrganization?.nameLocalRus === ORGANIZATION, [selectedOrganization]);

  const showSubdepartmentField = useMemo(() => (
    departmentsSelectOptions?.find((item: { value: number; label: string; }) =>
      item.value === departmentFieldValue)?.label === DEPARTMENT
  ), [departmentsSelectOptions, departmentFieldValue]);

  const reg = /[а-яА-ЯёЁa-zA-Z0-9]+$/;
  const isValidFullName = reg.test(fullNameFieldValue)

  const handleGetRegistrar = useCallback(() => {
    if (commonOrganizationFieldValue && isValidFullName && fullNameFieldValue) {
      setIsCreateNew(false);
      resetField('firstName');
      resetField('lastName');
      resetField('middleName');
      resetField('email');
      resetField('comment')
      resetField('creationDate');
      resetField('organizationCustom');
      setValue('phoneNumber', '');
      setValue('payment', FREE_PAYMENT_VALUE);
      setValue('speed', COMMON_SPEED_VALUE);
      removeItemSessionStorage(
        'registrarComment', 'registrarEmail', 'registrarLastName', 'registrarFirstName', 'registrarMiddleName', 'registrarPhoneNumber', 'registrarEmail', 
        'registrarSpecialistUlid', 'registrarCommonOrganization','registrarOrganizationCustom', 'registrarOrganization','registrarDepartment','registrarSubdepartment')
      findServiceUsers(fullNameFieldValue, selectedOrganization?.id);
      setRegistrarSubmittedValue(fullNameFieldValue);
      setRegistrarRequested(true);


      //set new org
      let commonOrg = getValues('commonOrganization')
      let org = getValues('commonOrganization')
      const foundedOrganization = organization.find(({ id }) => id === commonOrg || id === org);
      setSelectedOrganization(foundedOrganization);
      setToSessionStorage(
        [REGISTRAR.commonOrganization, commonOrg],
        [REGISTRAR.isCreatedNewDoctorProfile, 'false'],
        [REGISTRAR.fullName, fullNameFieldValue],
        [REGISTRAR.lastName, fullNameFieldValue],
        );
    }
    if (!isValidFullName) {
      setError('fullName', {})
    }
    if (!commonOrganizationFieldValue) {
      setError('commonOrganization', { types: { required: true } })
    }

    if (!fullNameFieldValue) {
      setError('fullName', { types: { required: 'Это поле не может быть пустым' } })
    }
  }, [setValue,getValues, resetField, findServiceUsers, fullNameFieldValue, selectedOrganization, organization, setSelectedOrganization,
    commonOrganizationFieldValue, setError, isValidFullName, setRegistrarSubmittedValue]);

  const onCreate = useCallback((e) => {
    e.preventDefault();
    setIsCreateNew(true);
    setSelectedSpecialist(null);
    resetField('firstName');
    resetField('lastName');
    resetField('middleName');
    resetField('email');
    resetField('comment')
    resetField('creationDate');
    resetField('organizationCustom');
    setValue('phoneNumber', '');
    setValue('payment', FREE_PAYMENT_VALUE);
    setValue('speed', COMMON_SPEED_VALUE);
    removeItemSessionStorage(
      'registrarComment', 'registrarEmail', 'registrarLastName', 'registrarFirstName', 'registrarMiddleName', 'registrarPhoneNumber', 'registrarEmail', 
      'registrarSpecialistUlid', 'registrarCommonOrganization','registrarOrganizationCustom', 'registrarOrganization','registrarDepartment','registrarSubdepartment')
    setToSessionStorage(
        [REGISTRAR.isCreatedNewDoctorProfile, 'true'],
        [REGISTRAR.commonOrganization, getValues('commonOrganization')],
        [REGISTRAR.customFullName, getValues('lastName') ?? fullNameFieldValue]);
      if (serviceUsersList?.length) dispatch(resetSelectedServiceUsers())
  }, [setValue, resetField, setSelectedSpecialist,dispatch,fullNameFieldValue,getValues,serviceUsersList?.length]);

  const [specialistSearchResultTitle, setSpecialistSearchResultTitle ] = useState<string>('');
  const [specialistAltResultTitle, setSpecialistAltResultTitle ] = useState<string>('');

  useEffect(() => {
    const [lastName,firstName,isCreatedNewDoctorProfile] = getFromSessionStorage(REGISTRAR.lastName,REGISTRAR.firstName, REGISTRAR.isCreatedNewDoctorProfile);
    const isNewDoctorProfile = isCreatedNewDoctorProfile === 'true'
    // let message = 'Специалист выбран. Проверьте данные';
    // let alt = 'Если данные неверные, создайте другой профиль'
    if(!registrarSubmittedValue){
      setSpecialistSearchResultTitle('');
      setSpecialistAltResultTitle('')
    }
    let message = '';
    let alt = '';
    if(selectedSpecialist?.ulid){
        message = 'Специалист найден. Проверьте данные';
        alt = 'Если данные неверные, создайте другой профиль'
    }
    if(serviceUsersList?.length > 1 && !selectedSpecialist){
      message = 'Найдено несколько специалистов';
      alt = 'Выберите подходящего или создайте другой профиль' 
    }
    if (!serviceUsersList?.length && lastName && isNewDoctorProfile) {
      message = 'Специалист не был найден.';
      alt = ''
    }
    if (!serviceUsersList?.length && firstName && !selectedSpecialist?.ulid) {
      message = 'Специалист не был найден.';
      alt = 'Вы создали этот профиль специалиста'
    }
    if (!serviceUsersList?.length && lastName) {
      message = 'Специалист не найден. Создайте профиль специалиста';
      alt = ''
    }
    setSpecialistSearchResultTitle(message);
    setSpecialistAltResultTitle(alt)
  }, [serviceUsersList.length,selectedSpecialist,registrarSubmittedValue]);

  const isCommonOrganizationRequired = useMemo(
    () => (registrarRequested && !serviceUsersList.length) || isCreateNew ? validations.organization : {},
    [registrarRequested, isCreateNew, serviceUsersList.length]
  );

  const renderCreateNewRegistrarBtn = useMemo(() => (
    <TWrongData>
      <TLinkButton variant={VARIANT.LINK} onClick={onCreate}>СОЗДАТЬ</TLinkButton> другой профиль
    </TWrongData>
  ), [onCreate]);

  useEffect(() => {
    if (commonOrganizationFieldValue) {
      clearErrors('commonOrganization');
    }
    if (fullNameFieldValue) {
      clearErrors('fullName');
    }
  }, [commonOrganizationFieldValue, clearErrors, fullNameFieldValue])

  const errorMessage = getErrorMessage(formState.errors, 'fullName', errors) || formState?.errors?.fullName?.types?.required;

  const renderDateAndPaymentFields = useMemo(() => {
    return (
    <TDateAndPaymentFieldsWr>
      <TTitle>Дата направления на исследование</TTitle>
      <THint focused={isTooltipFocused}>
        <span>Дата <b>фактического</b> направления пациента на исследование может отличаться от текущей</span>
      </THint>
      <TDateInputWr onMouseEnter={() => setTooltipFocused(true)} onMouseLeave={() => setTooltipFocused(false)} >
        <Controller
          control={control}
          name='creationDate'
          render={({ field: { onChange, value } }) => (
            <>
            <InputDayPicker
              id='creationDate'
              date={value}
              onChange={onChange}
              popperPlacement='right-start'
              placeholderText={`Дата создания направления ${currentDate}`}
              filterDate={disableFutureDays}
              className='mb40'
            />
            </>
          )}
        />
      </TDateInputWr>
      <TSubTitle>Форма обслуживания</TSubTitle>
      <TInputsWr>
        <Controller
          control={control}
          name='payment'
          rules={validations.payment}
          defaultValue={FREE_PAYMENT_VALUE}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <>
              <Radio selected={value} value={PAID_PAYMENT_VALUE} label='Платно' onChange={onChange} />
              <Radio selected={value} value={FREE_PAYMENT_VALUE} label='Бесплатно' onChange={onChange} />
            </>
          )}
        />
      </TInputsWr>
      <TSubTitle>Приоритет</TSubTitle>
      <TInputsWr>
        <Controller
          control={control}
          name='speed'
          rules={validations.speed}
          defaultValue={COMMON_SPEED_VALUE}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <>
              <Radio selected={value} value={URGENT_SPEED_VALUE} label='Срочно' onChange={onChange} />
              <Radio selected={value} value={COMMON_SPEED_VALUE} label='Планово' onChange={onChange} />
            </>
          )}
        />
      </TInputsWr>
    </TDateAndPaymentFieldsWr>
  )}, [control,isTooltipFocused]);


  // const hasSavedCustomDoctor = useMemo(() => {
  //   const [lastName] = getFromSessionStorage(REGISTRAR.lastName);
  //   let isSavedCustomDoctorsaved = !!lastName;
  //   if (!selectedSpecialist && serviceUsersList?.length && lastName) {
  //     isSavedCustomDoctorsaved = serviceUsersList?.every((value: ServiceUser) => value.userProfile.lastName !== lastName)
  //   }

  //   return isSavedCustomDoctorsaved;
  // }, [selectedSpecialist, serviceUsersList]);




  useEffect(() => {
    const [fullName, customFullName, isCreatedNewDoctorProfile] = getFromSessionStorage(REGISTRAR.fullName, REGISTRAR.customFullName, REGISTRAR.isCreatedNewDoctorProfile);
    let isNewDoctorProfile = isCreatedNewDoctorProfile === 'true';
    if(isNewDoctorProfile) setIsCreateNew(true)
    // else setIsCreateNew(false)

    if ((selectedSpecialist === null && !customFullName) || (isCreateNew && !isNewDoctorProfile)) {
      setSelectedSpecialist(null);
      !isCreatedNewDoctorProfile && removeItemSessionStorage('registrarOrganizationCustom','registrarComment', 'registrarEmail', 'registrarLastName', 'registrarFirstName', 'registrarMiddleName', 'registrarPhoneNumber', 'registrarEmail', 'registrarSpecialistUlid')

      setToSessionStorage(
        [REGISTRAR.fullName, getValues('lastName') ?? fullNameFieldValue],
        [REGISTRAR.customFullName, getValues('lastName') ?? fullNameFieldValue]
      )
      if (serviceUsersList?.length) dispatch(resetSelectedServiceUsers())

    }
    if (isCreateNew && fullName) {
      setSelectedSpecialist(null);
    //   removeItemSessionStorage('registrarComment', 'registrarEmail', 'registrarLastName', 'registrarFirstName', 'registrarMiddleName', 'registrarPhoneNumber', 'registrarEmail', 'registrarSpecialistUlid', 'registrarCommonOrganization', 'registrarOrganization', 'registrarSubdepartment')
    //   setToSessionStorage(
    //     [REGISTRAR.isCreatedNewDoctorProfile, 'true'],
    //     [REGISTRAR.commonOrganization, getValues('commonOrganization')],
    //     [REGISTRAR.customFullName, getValues('lastName') ?? fullNameFieldValue]);
    //   if (serviceUsersList?.length) dispatch(resetSelectedServiceUsers())
    }
    if (selectedSpecialist !== null && !isNewDoctorProfile) {
      setToSessionStorage([REGISTRAR.customFullName, '']);
      removeItemSessionStorage('registrarOrganizationCustom','registrarIsCreatedNewDoctorProfile', 'registrarComment', 'registrarEmail', 'registrarLastName', 'registrarFirstName', 'registrarMiddleName', 'registrarPhoneNumber', 'registrarEmail', 'registrarSpecialistUlid', 'registrarSubdepartment')
    }
  }, [selectedSpecialist, handleGetRegistrar, fullNameFieldValue, getValues, setSelectedSpecialist, isCreateNew, serviceUsersList, dispatch]);

  const isRegistrarSearchActive = useMemo(() => {
    return (!!selectedSpecialist?.userProfile?.lastName && 
      (selectedSpecialist?.userProfile?.lastName ?? '')?.toLowerCase()?.includes(fullNameFieldValue?.toLowerCase())) ||
      (registrarRequested && registrarSubmittedValue === fullNameFieldValue);
  }, [selectedSpecialist, registrarRequested, registrarSubmittedValue, fullNameFieldValue]);

  const onClearRegistrarSearch = useCallback(async () => {
    setSelectedSpecialist(null);
    resetField('firstName');
    resetField('lastName');
    resetField('middleName');
    resetField('email');
    resetField('comment')
    resetField('creationDate');
    resetField('oganizationCustom');
    setValue('phoneNumber', '');
    setValue('payment', FREE_PAYMENT_VALUE);
    setValue('speed', COMMON_SPEED_VALUE);
    setValue('fullName', '');
    setRegistrarSubmittedValue('');
    setRegistrarRequested(false);
  }, [setValue, resetField, setSelectedSpecialist, setRegistrarSubmittedValue,setRegistrarRequested]);

  const customDoctorValidateHandler = useCallback((isValid:boolean) => {
    return isCustomDoctorValidateHandler(isValid);
  },[isCustomDoctorValidateHandler]);


  return (
    <>
      <TTitle>Поиск направителя</TTitle>
      <TInfo>УЗ направляющей стороны должно совпадать с учреждением, выдавшим амбулаторную карту пациенту</TInfo>
      <Controller
        control={control}
        name='commonOrganization'
        rules={isCommonOrganizationRequired}
        render={({ field: { onChange, value, ref }, fieldState: { invalid } }) => (
          <InputSelect
            inputRef={ref}
            onChange={(value) => {
              setValue('fullName', '');
              setRegistrarSubmittedValue('');
              setRegistrarRequested(false);
              setSelectedSpecialist(null);
              return onChange(value)
            }}
            value={value}
            options={organizationsSelectOptions}
            placeholder='Учреждение здравоохранения'
            error={invalid}
          />
        )}
      />
      {<Controller
        control={control}
        name={'fullName'}
        rules={{...validations.fullName}}
        render={({ field: { onChange, value }, }) => {
          return (<InputSearch
            cb={(data) => isRegistrarSearchActive ? onClearRegistrarSearch() : handleGetRegistrar()}
            placeholder='Введите фамилию врача'
            buttonText={'Найти'}
            error={!!formState.errors.fullName}
            errorText={errorMessage}
            maxLength={25}
            onBlur={async ({ target }) => {
              setValue('fullName', target.value.trim());
              await trigger("fullName");
            }}
            value={value}
            defaultValue={''}
            isClearButtonActive={isRegistrarSearchActive}
            onChange={({target}) => {
              setRegistrarSubmittedValue('');
              setRegistrarRequested(false);
              setSelectedSpecialist(null);
              resetField('department');
              resetField('subdepartment');
              setValue('payment', FREE_PAYMENT_VALUE);
              setValue('speed', COMMON_SPEED_VALUE);
              return onChange(capitalize(target?.value?.trim()))
            }}
            buttonDisabled={!value?.trim()}
            className='mb40'
          />
          )
        }}
      />}
      {registrarRequested && (
        <>
          {/* Title */}
          {!isCreateNew && <>
            <TTitle>{specialistSearchResultTitle}</TTitle>
            {!!specialistAltResultTitle && <TInfo>{specialistAltResultTitle}</TInfo>}
          </>
          }
          {isCreateNew && <>
            <TTitle>Создайте профиль специалиста</TTitle>
            {!!specialistAltResultTitle && <TInfo>{specialistAltResultTitle}</TInfo>}
          </>
          }
          {!selectedSpecialist && !!serviceUsersList?.length && !isCreateNew && (
            <TRegistrarList>
            {serviceUsersList.map(({ ulid, email, userProfile: { organization, organizationCustom, department, subdepartment, lastName, firstName, middleName, phoneNumber } }) => (
              <TRegistrarBlockWr key={ulid}>
                  <RegistrarBlock
                    organization={organization}
                    department={department}
                    subdepartment={subdepartment}
                    organizationCustom={organizationCustom}
                    lastName={lastName}
                    firstName={firstName}
                    middleName={middleName}
                    phoneNumber={phoneNumber}
                    email={email}
                  />
                  {serviceUsersList.length > 1 && (
                    <Button size={SIZE.SMALL} variant={VARIANT.TRANSPARENT} onClick={() => {
                      selectSpecialist(ulid)
                      !!organizationCustom && setValue('organizationCustom', organizationCustom);
                    }}>Выбрать специалиста</Button>
                  )}
                </TRegistrarBlockWr>
              ))}
              {renderCreateNewRegistrarBtn}
            </TRegistrarList>
          )}
          {!selectedSpecialist && (!serviceUsersList?.length || isCreateNew) && (
            <>
                <NotFoundBlock
                  key={`NotFoundBlock_${fullNameFieldValue}`}
                  departmentOptions={departmentsSelectOptions ?? []}
                  showDepartmentField={showDepartmentField}
                  showSubdepartmentField={showSubdepartmentField}
                  isCreateNew={isCreateNew}
                  organization={selectedOrganization}
                  secialistLasName={registrarRequested ? fullNameFieldValue : undefined}
                  isCustomDoctorValid={customDoctorValidateHandler}
                />
                {renderDateAndPaymentFields}
              </>
          )}
          {selectedSpecialist?.ulid && (
            <>
              <RegistrarBlock
                email={selectedSpecialist.email}
                subdepartment={selectedSpecialist.userProfile.subdepartment}
                department={selectedSpecialist.userProfile.department}
                organizationCustom={selectedSpecialist?.userProfile?.organizationCustom ?? ''}
                firstName={selectedSpecialist.userProfile.firstName}
                lastName={selectedSpecialist.userProfile.lastName}
                middleName={selectedSpecialist.userProfile.middleName}
                organization={selectedSpecialist.userProfile.organization}
                phoneNumber={selectedSpecialist.userProfile.phoneNumber}
              />
              {renderDateAndPaymentFields}
              <br />
              {renderCreateNewRegistrarBtn}
            </>
          )}
        </>
      )}
    </>
  );
};

export default RegistrarForm;
